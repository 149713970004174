import axios from 'axios';

const token = process.env.REACT_APP_API_KEY;
//remover o base url em produção
const api = process.env.REACT_APP_BASE_URL+process.env.REACT_APP_API;

const Api = {
  collections: function() {
    return axios.get(api+'collections/listCollections?token='+token).then(res => {
        return res.data;
      })
  },
  collection: function(slug) {
    return axios.post(api+'collections/get/'+slug+'?token='+token).then(res => {
        return res.data;    
      })
  },
  form: function(data) {     
    return axios.post(api+'forms/submit/contacto?token='+token,{
        form: data
    }).then(res => {
        return res.data;
    })
  },
  thumbnail: function(id,m,w,h,q,b64) {
    return axios.post(api+'cockpit/image?token='+token,{
			src: id,
			m: m ? m : 'thumbnail',
			w: w ? w : 270,
			h: h ? h : 270,
			q: q ? q : 80,
			b64: b64 ? b64 : true,
		}).then(res => {
        return res.data;    
    })
  }
}

export default Api