import React from 'react';
import { Link } from 'react-scroll'
import { useTranslation} from 'react-i18next';
import banner from '../img/mark-merner-ZK69PVQj5nc-unsplash.jpg';
import house from '../img/house-2.png';

const Banner = (props) => {

    const { i18n } = useTranslation();

    let divStyle = {
        backgroundImage: 'url(' + banner + ')'
    };

    return (
        <div className={ (props.path !== '/' ? "creative-banner-small" : "") + " creative-banner bg-img cover-background theme-overlay" } data-overlay-dark="9" style={divStyle}>
            <div className="container creative-banner-stuff">
                <div className="row">   
                    <div className="col-lg-6 col-md-12 sm-margin-40px-bottom xs-margin-30px-bottom">
                        <div className="header-text sm-width-75 xs-width-100">
                            <h1 className="line-height-55 md-line-height-50 xs-line-height-40 xs-font-size28 wow fadeInUp text-white" data-wow-delay=".1s">{i18n.t('title')}</h1>
                            <p className="text-white font-size16 line-height-28 xs-font-size14 xs-line-height-26 margin-30px-bottom sm-margin-20px-bottom width-80 xs-width-90 wow fadeInUp" data-wow-delay=".2s">{i18n.t('subtitle')}</p>
                            <div className="wow fadeInUp story-video" data-wow-delay=".4s">                      
                                <Link className="butn style-two md-lg margin-10px-right vertical-align-middle" to="sobre" spy={true} smooth={true} duration={500}>
                                    {i18n.t('common:more_info')} <i className="fa fa-arrow-right"></i>
                                </Link>  
                            </div>
                        </div>
                    </div>                    
                    <div className="col-lg-6 col-md-12 sm-text-center">
                        <div className="creative-banner-img">
                            <img src={house} className="img-fluid float-right width-100 sm-width-auto" alt="" />
                        </div>
                    </div>        
                </div>
            </div>
            <div className="wave-area sm-display-none">
                <div className="wave"></div>
                <div className="wave"></div>
            </div>
        </div>
    )
}

export default Banner;