import React, {useState} from 'react';
import useForm from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import { Beetle as Button } from 'react-button-loaders'
import Api from '../api/axios';

const Form = () => {

    const { i18n } = useTranslation();

    const { handleSubmit, register, errors } = useForm();
    const [sending, setSending] = useState(false);
    
    const onSubmit = values => {
        Api.form(values).then(() => {
            //console.log(resp)  
            setSending('finished');
        }) 
    };

    return (
 
        <section data-scroll-index="6">
          <div className="container">
              <div className="section-heading">
                  <h3>{i18n.t('form.title')} </h3>
                  <p className="width-55 sm-width-75 xs-width-95">
                     {i18n.t('form.subtitle')}
                 </p>
              </div>
              <div className="row wow fadeIn">
                  <div className="col-lg-6 col-md-12 order-2 order-lg-1">
                      <form className="rd-mailform" onSubmit={handleSubmit(onSubmit)}>
                          <div className="row">
                              <div className="col-md-6">
                                  <div className="form-wrap">
                                      <input className="form-input" type="text" name="nome" placeholder={i18n.t('form.name')} 
                                      
                                      ref={register({
                                        required: i18n.t('form.required')
                                      })}

                                      />      
                                      <span className="text-orange">{errors.nome && errors.nome.message}</span>                       
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="form-wrap">
                                      <input className="form-input" type="number" name="telefone" placeholder={i18n.t('form.phone')} ref={register({
                                        required: i18n.t('form.required'),
                                        pattern: {
                                          value: /^[0-9]/i,
                                          message: i18n.t('form.phone_invalid')
                                        }
                                      })} />
                                      <span className="text-orange">{errors.telefone && errors.telefone.message}</span>
                                  </div>
                              </div>
                              <div className="col-md-12">
                                  <div className="form-wrap">
                                      <input className="form-input" type="email" name="email" placeholder={i18n.t('form.email')} ref={register({
                                        required: i18n.t('form.required'),
                                        pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                          message: i18n.t('form.invalid_email')
                                        }
                                      })} />
                                      <span className="text-orange">{errors.email && errors.email.message}</span>
                                  </div>
                              </div>
                              <div className="col-md-12">
                                  <div className="form-wrap">
                                      <textarea className="form-input" name="mensagem" rows="5" placeholder={i18n.t('form.message')}  ref={register({
                                        required: i18n.t('form.required'),
                                      })}></textarea>
                                      <span className="text-orange">{errors.mensagem && errors.mensagem.message}</span>
                                  </div>
                              </div>

                              <div className="col-md-12 margin-15px-top">                         
                                  <Button bgColor={'#29c2c1'} type="submit" state={sending}>{i18n.t('form.send')}</Button>
                              </div>
                          </div>
                      </form>
                  </div>
                  <div className="col-lg-6 col-md-12 order-1 order-lg-2">
                      <div className="contact-info padding-50px-left md-padding-30px-left sm-no-padding-left sm-margin-30px-bottom"> 
                          <div className="item">
                              <span className="icon"><i className="fa fa-map-marker text-theme-color"></i></span>
                              <div className="cont">
                                  <h6 className="text-capitalize">{i18n.t('address')}:</h6>
                                  <p>Digitalmente</p>
                                  <p>Rua Padre Donaciano Abreu Freire nº43 RC/A</p>
                                  <p>3860 - Estarreja</p>
                              </div>
                          </div>
                          <div className="item">
                              <span className="icon"><i className="fa fa-envelope text-theme-color"></i></span>
                              <div className="cont">
                                  <h6>Email: </h6>
                                  <p><a href="mailto:pvspread@pvspread.com">pvspread@pvspread.com</a></p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

    )

}

export default Form;