import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from 'react-ga';

import './i18n';
import 'bootstrap/dist/css/bootstrap.css';

import App from './App';

const history = createBrowserHistory();

const trackingId = "UA-78533-95"; // Replace with your Google Analytics tracking ID

ReactGA.initialize(trackingId);

history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

ReactDOM.render( <Router history={history}><App /></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

