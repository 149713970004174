import React, {Suspense, useEffect, useState } from "react";
import { useLocation , Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import Header from './components/header';
import Banner from './components/banner';
import Footer from './components/Footer';
import Form from './components/Form';
import Loader from './components/Loader';

import './App.css';
import './css/default.css';
import './css/styles.css';
import './css/nav-menu.css';
import 'font-awesome/css/font-awesome.min.css';

const About = React.lazy(() => import('./components/About'));
const Enquadramento = React.lazy(() => import('./components/Enquadramento'));
const Objetivos = React.lazy(() => import('./components/Objetivos'));
const Consorcio = React.lazy(() => import('./components/Consorcio'));
const Atividades = React.lazy(() => import('./components/Atividades'));
const Documentacao = React.lazy(() => import('./components/Documentacao'));
const Noticias = React.lazy(() => import('./components/Noticias'));

const App = () => {

  let location = useLocation()

  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
      setCurrentPath(location.pathname);
      window.scrollTo(0, 0)
  },[location])

    return (
      <Suspense fallback={<Loader />}>
      <div className="App">
        <Helmet titleTemplate="%s - PV Spread" defaultTitle="PV Spread - Trusted photovoltaics">
          <meta property="og:url" content={process.env.REACT_APP_BASE_URL+location.pathname}/>
          <meta name="og:title" content="PV SPREAD - Trusted photovoltaics"/>
          <meta name="og:description" content="Projeto PV SPREAD - Um inovador ecossistema de apoio a todo o ciclo da fase de projeto fotovoltaico"/>
        </Helmet>
        <div className="main-wrapper">  
          <Header path={currentPath}></Header>
          <Banner path={currentPath}></Banner>    
          <Route exact path="/" component={About} />   
          <Route exact path="/enquadramento" component={Enquadramento} />   
          <Route exact path="/objetivos" component={Objetivos} /> 
          <Route exact path="/atividades" component={Atividades} /> 
          <Route exact path="/documentacao" component={Documentacao} /> 
          <Route exact path="/noticias" component={Noticias} />
          <Consorcio></Consorcio>
          <Form/>
          <Footer/>
        </div>
      </div>
      </Suspense>
    );
  
}

export default App;
