import React, {useEffect,useState} from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Dropdown from './Dropdown';
import logo from '../img/logo-white.png';

const Header = () => {

    const { i18n } = useTranslation();

    const [fixed, setFixed] = useState(false);
    const [mobile, setMenu] = useState(false);
   
    function toggle() {
      setMenu(mobile === true ? false : true);
    }

    const handleScroll = () => {

      const top = window.pageYOffset || document.documentElement.scrollTop;

      setFixed( top===0 ? false:true );

    };

    const changeLanguage = lang => {
      i18n.changeLanguage(lang)
    };
     

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);


    return (    
      <header className={ ( fixed ? "scrollHeader ":"") + "header onepage-header"}>
          <div className="container no-padding sm-padding-15px-lr">
              <div className="menu_area alt-font">
                  <nav className="navbar navbar-expand-lg no-padding">
                      <div className="container sm-position-relative">
                          <div className="navbar-header navbar-header-custom">
                            <Link to="/" className="navbar-brand white-logo">
                                <img src={logo} alt="pvspread" />
                            </Link>
                          </div>
                            <button onClick={toggle} className="navbar-toggler"></button>
                            <ul className={ ( mobile ? "show" : "") + " navbar-nav ml-auto"}>
                              <li className="nav-item">                              
                                <Link className="nav-link" to="/">{i18n.t('header.home')}</Link> 
                              </li>
                              <li className="nav-item">
                                <Link className="nav-link" to="/enquadramento">{i18n.t('header.vision')}</Link>                               
                              </li>
                              <li className="nav-item">
                                <Link className="nav-link" to="/objetivos">{i18n.t('header.objectives')}</Link>
                              </li>
                              <li className="nav-item">
                                <Link className="nav-link" to="/atividades">{i18n.t('header.activities')}</Link>
                              </li>
                              <li className="nav-item">
                                <Link className="nav-link" to="/documentacao">{i18n.t('header.docs')}</Link>
                              </li>
                              <li className="nav-item">
                                <Link className="nav-link" to="/noticias">{i18n.t('header.news')}</Link>
                              </li>
                              {/*  <li className="nav-item">
                                <Dropdown passedFunction={changeLanguage} />
                              </li>    */}      
                          </ul>
                      </div>
                  </nav>           
              </div>
          </div>         
      </header>
    )
}

export default Header;