import React from 'react';
import Loader from 'react-loader-spinner'

const Loading = () => {

    return (
        <div className="loading">
            <div className="loading-icon">
                <Loader
                    type="Puff"
                    color="#fff"
                    height={50}
                    width={50}
                />
            </div>  
        </div>
    )
}

export default Loading;