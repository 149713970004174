import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use(Backend)
.init({
        detection: {
            order: ['subdomain','localStorage'],
            lookupCookie: 'pvspread',
            lookupLocalStorage: 'pvspread',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,
            // cache user language on
            caches: ['localStorage'],
            cookieDomain: 'pvspread'
        },
        debug: false,
        defaultNS: 'common',
        fallbackLng: 'pt',
        backend: {
            loadPath: process.env.PUBLIC_URL+'/locales/{{lng}}/{{ns}}.json',
            parse: JSON.parse,
           crossDomain: true,
           withCredentials: false,
        },
        //lng: 'pt', // 'en' | 'es'
        // Using simple hardcoded resources for simple example
        react: {
            wait: false
        } 
    })

export default i18n