import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {

    const { i18n } = useTranslation();

    return (
        <footer>
            <div className="container">
                <div className="row margin-30px-bottom padding-30px-bottom border-bottom border-color-light-white">
                   
                    <div className="col-lg-4 col-md-12 sm-margin-30px-bottom text-center">
                        <h3 className="footer-title-style text-white">Email</h3>
                        <p className="font-size16 sm-font-size14"><a href="mailto:pvspread@pvspread.com">pvspread@pvspread.com</a></p>
                    </div>
                    <div className="col-lg-4 col-md-12 text-center">
                        <h3 className="footer-title-style text-white">{i18n.t('address')}</h3>
                        <p className="font-size16 sm-font-size14">Digitalmente</p>
                        <p className="font-size14 sm-font-size11">Rua Padre Donaciano Abreu Freire nº43 RC/A 3860 - Estarreja</p>
                    </div>
                    <div className="col-lg-4 col-md-12 text-center">
                        <h3 className="footer-title-style text-white">Web</h3>
                        <p className="font-size16 sm-font-size14"><a rel="noopener" href="http://pvspread.com">pvspread.com</a></p>
                    </div>
                </div>
            </div>
            <div className="footer-bar">
                <div className="container">
                    <p className="xs-font-size13">{i18n.t('title')}</p>
                </div>
            </div>
        </footer>
    )

}

export default Footer;